@import url(https://fonts.googleapis.com/css2?family=Signika:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
* {
  font-family: "Signika", sans-serif;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::selection {
  color: #2F4F4F;
  background: #FADCD9;
}

.main-div,
.about,
.projects,
.skills,
.volunteer,
.experience,
.education,
.certificates,
.contact, 
.not-found {
  min-height: -webkit-max-content;
  min-height: max-content;
  align-items: center;
  justify-content: center;
}

.about,
.projects,
.skills,
.volunteer,
.education,
.certificates,
.experience,
.contact, 
.not-found {
  min-height: 95vh !important;
  display: block;
  padding: 7rem 5rem 3rem 5rem;
  background: #FFFFF0;
}

.about h1,
.projects h1,
.skills h1,
.volunteer h1,
.education h1,
.certificates h1,
.experience h1,
.contact h1 {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5rem;
  color: #fab1a9;
  margin-bottom: 5rem;
}

.timeline .experience-title,
.timeline .experience-title,
.timeline .certificates-title {
  color: #2F4F4F;
}

.timeline .experience-subtitle,
.timeline .certificates-subtitle,
.timeline .education-subtitle {
  font-size: 1rem;
}

.timeline .experience-skills,
.timeline .certificates-skills,
.timeline .education-skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.experience-skills span,
.certificates-skills span,
.education-skills span {
  margin: 0.1rem;
  padding: 0.3rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  background-color: #EAEAEA;
}

.vertical-timeline::before {
  background: #FADCD9 !important;
}

/* ----------- NavBar ----------- */

.nav-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 500;
}

.scrolled {
  background: #FFFFF0;
  opacity: 0.9;
  transition: 1s ease-in;
}

.nav-contents {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9rem;
  color: #2F4F4F !important;
  margin: .5rem;
}

.nav-contents:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  -webkit-text-decoration-color: #FAB1A9;
          text-decoration-color: #FAB1A9;
}

/* ----------- Main Div ----------- */

.main-div {
  min-height: 95vh !important;
  display: flex;
  background: #FFFFF0;
  justify-content: space-evenly;
}

.main-div > .header-pic {
  width: 50%;
}

.main-div > .header-name {
  font-size: 3rem;
  color: #2F4F4F;
}

.header-name h1 {
  font-family: "Indie Flower", cursive;
  font-size: larger;
}

.header-name > .titles {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.titles h5 {
  padding-top: 3rem;
  color: #848383;
}

.titles small {
  font-size: medium;
  color: #BEBEBE;
}

.here-link{
  color: #FAB1A9;
  text-decoration: none;
}

.about p {
  text-align: justify;
  -webkit-hyphens: auto;
          hyphens: auto;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  color: #2F4F4F;
}

.about .quote {
  background-color: #eeeee4;
  border-radius: 1rem;
  padding: 1.5rem;
}

.quote svg {
  fill: #fab1a9;
  opacity: 0.7;
  margin: 0 .3rem;
}

.quote span {
  font-style: italic;
}

.projects .subtitle {
  color: #2F4F4F;
  margin: 0 4rem 2rem 4rem;
}

.projects .project-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

.projects .item-card {
  max-width: 30%;
  margin: 1rem;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
}

.item-card img {
  width: 100%;
  border-radius: 2rem;
}

.item-card:hover .fade-img {
  /* opacity: 50%; */
  transition: all 0.3s ease-in;
}

.item-card p {
  text-align: center;
  position: absolute;
  width: 100%;
  color: #FADCD9;
  background-color: #BEBEBE;
  cursor: pointer;
  padding: 1rem;
  opacity: 0;
  bottom: 0;
  margin-bottom: 0;
  word-spacing: 2px;
  letter-spacing: 2px;
  font-weight: 600;
}

.item-card:hover p {
  opacity: 1;
  transition: all 0.7s ease-in;
}

.modali-header {
  background-color: #FFFFF0;
}

.modali-header button {
  color: #2F4F4F;
  background-color: #FFFFF0;
}

.content-modal {
  background-color: #FFFFF0;
  max-height: 80vh;
  text-align: center;
  scroll-behavior: auto;
  overflow-y: auto;
  overflow-x: auto;
}

.content-modal h2 {
  color: #006d77;
  margin-bottom: 1rem;
  font-weight: bolder;
}

.content-modal .website-link {
  margin-top: 2rem;
}

.website-link a {
  text-decoration: none;
  cursor: pointer;
  background-color: #f8edeb;
  color: #006d77;
  padding: 0.7rem;
  border-radius: 1rem;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.website-link a:hover {
  background-color: #fae1dd;
  color: #006d77;
}

.content-modal p {
  text-align: justify;
  -webkit-hyphens: auto;
          hyphens: auto;
}

.made-with b {
  color: #BEBEBE;
  border-bottom: 1px solid #FADCD9;
}

.made-with .tools-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.view-more button {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  color: #2F4F4F; 
  border: 2px solid #FADCD9;
}

.view-more button:hover {
  background-color: #FADCD9;
}

/* ======== Project's Cards ========== */

/* Cards */
.postcard {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
	
	.t-dark {
		color: #2F4F4F;
	}

  .postcard__img {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    position: relative;
  }

  .postcard__img_link {
    display: contents;
  }

  .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #FADCD9;
    transition: width 0.2s ease;
  }

  .postcard__text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .postcard__preview-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    height: 100%;
  }

  .postcard__tagbox {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
		padding: 0;
    justify-content: center;
  }

  .postcard__tagbox .tag__item {
    display: inline-block;
    background: #BEBEBE;
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    cursor: default;
    -webkit-user-select: none;
            user-select: none;
    transition: background-color 0.3s;
  }

  .postcard__tagbox .tag__item:hover {
    background: rgba(83, 83, 83, 0.8);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #BEBEBE, transparent 50%);
    opacity: 1;
    border-radius: 10px;
  }

  &:hover .postcard__bar {
    width: 100px;
  }
}

@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;

    .postcard__title {
      font-size: 2rem;
    }

    .postcard__tagbox {
      justify-content: start;
    }

    .postcard__img {
      max-width: 300px;
      max-height: 100%;
      transition: transform 0.3s ease;
    }

    .postcard__text {
      padding: 3rem;
      width: 100%;
    }

    .media.postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      background: #18151f;
      top: -20%;
      height: 130%;
      width: 55px;
    }

    &:hover .postcard__img {
      transform: scale(1.1);
    }

    &:nth-child(2n+1) {
      flex-direction: row;
    }

    &:nth-child(2n+0) {
      flex-direction: row-reverse;
    }

    &:nth-child(2n+1) .postcard__text::before {
      left: -12px !important;
      transform: rotate(4deg);
    }

    &:nth-child(2n+0) .postcard__text::before {
      right: -12px !important;
      transform: rotate(-4deg);
    }
  }
}
@media screen and (min-width: 1024px){
		.postcard__text {
      padding: 2rem 3.5rem;
    }
		
		.postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      
      top: -20%;
      height: 130%;
      width: 55px;
    }
}

@media screen and (min-width: 769px) {
	.green::before {
		background-image: linear-gradient(
			-80deg,
			transparent 50%
		);
	}
	.green:nth-child(2n)::before {
		background-image: linear-gradient(
			80deg,
			transparent 50%
		);
	}

	.blue::before {
		background-image: linear-gradient(
			-80deg,
			transparent 50%
		);
	}
	.blue:nth-child(2n)::before {
		background-image: linear-gradient(80deg, transparent 50%);
	}
	
	.yellow::before {
		background-image: linear-gradient(
			-80deg,
			transparent 50%
		);
	}
	.yellow:nth-child(2n)::before {
		background-image: linear-gradient(
			80deg,
			transparent 50%
		);
	}
}

.skills .subtitle {
  color: #2F4F4F;
  margin: 0 4rem 2rem 4rem;
}

.skills .skills-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.skills-container .skills-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
  box-shadow: 2px 2px 8px 2px #BEBEBE;
  border-radius: 1rem;
  margin: 0.8rem;
}

.skills-item .icon {
  font-size: 5rem;
}

.skills-item span {
  color: #2F4F4F;
  margin-top: 0.5rem;
}

.volunteer .subtitle {
    color: #2F4F4F;
    margin: 0 4rem 2rem 4rem;
}

.volunteer .volunteer-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.volunteer-container .volunteer-item {
    display: flex;
    color: #2F4F4F;
    width: 90%;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 2px 8px 2px #BEBEBE;
    border-radius: 1rem;
}

.volunteer-item .vol-img {
    width: 30%;
    margin: 1rem;
}

.volunteer-item .vol-text {
    margin: 1rem;
}

.contact .main-contact {
    width: 70%;
    text-align: center;
    margin: auto;
    color: #2F4F4F;
}

.main-contact p span {
    color: #FAB1A9;
    font-size: large;
}

.contact-form {
    margin-bottom: 4rem;
}

.contact-form input,
.contact-form textarea {
    background-color: #FFFFF0;
    border: 1px dotted #FAB1A9;
}
  
.contact-form input:focus,
.contact-form textarea:focus {
    background-color: #FFFFF0;
    border: 1px dotted #2F4F4F;
}


.contact-form .send-btn {
    width: 100%;
    border: 1px dotted #FAB1A9;
    color: #2F4F4F;
    font-weight: 800;
}

.contact-form .send-btn:hover {
    background-color: #FAB1A9;
    border-color: #2F4F4F;
}

.contact-container a {
    color: #2F4F4F;
    font-size: 1.8rem;
    margin: 0 1rem;
}


.contact-container a:hover {
    color: #FAB1A9;
}

.hide {
    display: none;
}

a:hover .hide {
    display: inline-block;
    position: absolute;
    margin-top: 2.5rem;
    margin-left: -2.3rem;
    z-index: 20;
    font-size: medium;
}
.footer {
  display: block;
  text-align: center;
  padding-bottom: .3rem;
  background: #FFFFF0;
}

.copyright {
  color: #2F4F4F;
}

.not-found {
    text-align: center;
}

.not-found h1 {
    font-family: "Indie Flower", cursive;
    color: #2F4F4F;
}

.not-found .gif {
    width: 45%;
    margin: auto;
}
/*  Laptop Screen */
@media only screen and (max-width: 1100px) {
  /* ----------- Main Div ----------- */

  .main-div {
    padding: 1rem;
  }

  .main-div > .header-name {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .header-name h1 {
    font-size: 3rem;
  }

  .header-name > .titles {
    font-size: 1.2rem;
  }

  /* ------------- Projects ------------ */

  .projects .project-items {
    justify-content: center;
  }

  .projects .item-card {
    max-width: 70%;
  }
}

/* Tablet Screen */
@media only screen and (max-width: 770px) {
  /* ----------- Main Div ----------- */

  .main-div {
    display: block;
    text-align: center;
  }

  .main-div > .header-pic {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .main-div > .header-name {
    width: 100%;
    padding-top: 30%;
  }

  .header-name h1 {
    font-size: 3.5rem;
  }

  /* ------------- Projects ------------ */

  .projects .project-items {
    justify-content: center;
    align-content: center;
  }

  .projects .item-card {
    max-width: 80%;
  }

  /* ------------- Contact ------------ */

  .contact .main-contact {
    width: 100%;
  }

  /* ------------ Volunteer ------------- */

  .volunteer-container .volunteer-item {
    flex-wrap: wrap;
    width: 100%;
    padding: 1.5rem;
    margin: 0;
  } 

  .volunteer-item .vol-text {
    margin: .5rem;
  }

  .volunteer-item .vol-img {
    width: 100%;
    height: auto;
    margin: 0;
  }

  /* ------------ Not Found ------------- */

  .not-found .gif {
    width: 100%;
  }
}

/* Mobile Screen */
@media only screen and (max-width: 600px) {
  /* ----------- NavBar ----------- */

  .nav-bar {
    display: block;
  }

  .scrolled {
    background: none;
    opacity: 0.9;
  }

  .navbar-toggler {
    border: none !important;
    border-radius: 1rem !important;
  }

  .navbar-collapse {
    background-color: rgba(190, 190, 190, 0.9);
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
  }

  /* ----------- Main Div ----------- */

  .main-div > .header-pic {
    width: 100%;
  }

  .header-name h1 {
    font-size: 2.5rem;
  }

  .header-name > .titles {
    font-size: 1.1rem;
  }

  /* ------------- Projects ------------ */

  .projects .item-card {
    max-width: 100%;
  }

  .projects .subtitle {
    margin: 0 .5rem 1.5rem .5rem;
  }

  /* ----------- Skills -------------- */

  .skills-container .skills-item {
    margin: 0.2rem;
    min-width: 30%;
  }

  .skills-item .icon {
    font-size: 3rem;
  }

  .skills-item span {
    font-size: 1rem;
  }

  .skills .subtitle {
    margin: 0 .5rem 1.5rem .5rem;
  }

  /* ------------ Volunteer ------------- */
  .volunteer .subtitle {
    margin: 0 .5rem 1.5rem .5rem;
  }

  /* ------------ Footer ------------- */

  .footer {
    padding: 1rem .5rem;
  }

  /* ------------ Contact ------------- */

  .contact-form .name-email {
    display: block;
  }

  /* ------------ Not Found ------------- */

  .not-found .gif {
    margin-top: 5rem;
  }

  /* ------------ Common Styles ------------- */
  .about, .projects, .skills, .volunteer, .experience, .education, .certificates, .contact, .not-found {
    padding: 5rem 2.5rem 1.5rem 2.5rem;
  }

  .about h1,
  .projects h1,
  .skills h1,
  .volunteer h1,
  .experience h1,
  .education h1,
  .certificates h1,
  .contact h1 {
    margin-bottom: 3rem;
  }

  .timeline .experience-title,
  .timeline .education-title,
  .timeline .certificates-title {
    font-size: 1.3rem;
  }

  .timeline .experience-subtitle,
  .timeline .education-subtitle,
  .timeline .certificates-subtitle {
    font-size: 0.8rem;
  }

  .experience-skills span,
  .certificates-skills span,
  .education-skills span {
    font-size: 0.6rem;
  }
}

/* -------- Common Style ---------- */

/* ----------- Header ------------- */

/* ------------- About ------------- */

/* ------------- Projects ------------ */

/* ----------- Skills -------------- */

/* ----------- Volunteer -------------- */

/* ------------ Contact ------------- */

/* ------------ Footer ------------- */

/* ------------ Not Found ------------- */

/* ----------- Media Queries ---------- */

