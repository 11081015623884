.volunteer .subtitle {
    color: #2F4F4F;
    margin: 0 4rem 2rem 4rem;
}

.volunteer .volunteer-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.volunteer-container .volunteer-item {
    display: flex;
    color: #2F4F4F;
    width: 90%;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 2px 8px 2px #BEBEBE;
    border-radius: 1rem;
}

.volunteer-item .vol-img {
    width: 30%;
    margin: 1rem;
}

.volunteer-item .vol-text {
    margin: 1rem;
}