
.contact .main-contact {
    width: 70%;
    text-align: center;
    margin: auto;
    color: #2F4F4F;
}

.main-contact p span {
    color: #FAB1A9;
    font-size: large;
}

.contact-form {
    margin-bottom: 4rem;
}

.contact-form input,
.contact-form textarea {
    background-color: #FFFFF0;
    border: 1px dotted #FAB1A9;
}
  
.contact-form input:focus,
.contact-form textarea:focus {
    background-color: #FFFFF0;
    border: 1px dotted #2F4F4F;
}


.contact-form .send-btn {
    width: 100%;
    border: 1px dotted #FAB1A9;
    color: #2F4F4F;
    font-weight: 800;
}

.contact-form .send-btn:hover {
    background-color: #FAB1A9;
    border-color: #2F4F4F;
}

.contact-container a {
    color: #2F4F4F;
    font-size: 1.8rem;
    margin: 0 1rem;
}


.contact-container a:hover {
    color: #FAB1A9;
}

.hide {
    display: none;
}

a:hover .hide {
    display: inline-block;
    position: absolute;
    margin-top: 2.5rem;
    margin-left: -2.3rem;
    z-index: 20;
    font-size: medium;
}