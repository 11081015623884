.projects .subtitle {
  color: #2F4F4F;
  margin: 0 4rem 2rem 4rem;
}

.projects .project-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

.projects .item-card {
  max-width: 30%;
  margin: 1rem;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  overflow: hidden;
  position: relative;
}

.item-card img {
  width: 100%;
  border-radius: 2rem;
}

.item-card:hover .fade-img {
  /* opacity: 50%; */
  transition: all 0.3s ease-in;
}

.item-card p {
  text-align: center;
  position: absolute;
  width: 100%;
  color: #FADCD9;
  background-color: #BEBEBE;
  cursor: pointer;
  padding: 1rem;
  opacity: 0;
  bottom: 0;
  margin-bottom: 0;
  word-spacing: 2px;
  letter-spacing: 2px;
  font-weight: 600;
}

.item-card:hover p {
  opacity: 1;
  transition: all 0.7s ease-in;
}

.modali-header {
  background-color: #FFFFF0;
}

.modali-header button {
  color: #2F4F4F;
  background-color: #FFFFF0;
}

.content-modal {
  background-color: #FFFFF0;
  max-height: 80vh;
  text-align: center;
  scroll-behavior: auto;
  overflow-y: auto;
  overflow-x: auto;
}

.content-modal h2 {
  color: #006d77;
  margin-bottom: 1rem;
  font-weight: bolder;
}

.content-modal .website-link {
  margin-top: 2rem;
}

.website-link a {
  text-decoration: none;
  cursor: pointer;
  background-color: #f8edeb;
  color: #006d77;
  padding: 0.7rem;
  border-radius: 1rem;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.website-link a:hover {
  background-color: #fae1dd;
  color: #006d77;
}

.content-modal p {
  text-align: justify;
  hyphens: auto;
}

.made-with b {
  color: #BEBEBE;
  border-bottom: 1px solid #FADCD9;
}

.made-with .tools-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.view-more button {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 4px;
  transition-duration: 0.4s;
  cursor: pointer;
  color: #2F4F4F; 
  border: 2px solid #FADCD9;
}

.view-more button:hover {
  background-color: #FADCD9;
}

/* ======== Project's Cards ========== */

/* Cards */
.postcard {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
	
	.t-dark {
		color: #2F4F4F;
	}

  .postcard__img {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    position: relative;
  }

  .postcard__img_link {
    display: contents;
  }

  .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #FADCD9;
    transition: width 0.2s ease;
  }

  .postcard__text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .postcard__preview-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    height: 100%;
  }

  .postcard__tagbox {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
		padding: 0;
    justify-content: center;
  }

  .postcard__tagbox .tag__item {
    display: inline-block;
    background: #BEBEBE;
    border-radius: 3px;
    padding: 2.5px 10px;
    margin: 0 5px 5px 0;
    cursor: default;
    user-select: none;
    transition: background-color 0.3s;
  }

  .postcard__tagbox .tag__item:hover {
    background: rgba(83, 83, 83, 0.8);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #BEBEBE, transparent 50%);
    opacity: 1;
    border-radius: 10px;
  }

  &:hover .postcard__bar {
    width: 100px;
  }
}

@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;

    .postcard__title {
      font-size: 2rem;
    }

    .postcard__tagbox {
      justify-content: start;
    }

    .postcard__img {
      max-width: 300px;
      max-height: 100%;
      transition: transform 0.3s ease;
    }

    .postcard__text {
      padding: 3rem;
      width: 100%;
    }

    .media.postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      background: #18151f;
      top: -20%;
      height: 130%;
      width: 55px;
    }

    &:hover .postcard__img {
      transform: scale(1.1);
    }

    &:nth-child(2n+1) {
      flex-direction: row;
    }

    &:nth-child(2n+0) {
      flex-direction: row-reverse;
    }

    &:nth-child(2n+1) .postcard__text::before {
      left: -12px !important;
      transform: rotate(4deg);
    }

    &:nth-child(2n+0) .postcard__text::before {
      right: -12px !important;
      transform: rotate(-4deg);
    }
  }
}
@media screen and (min-width: 1024px){
		.postcard__text {
      padding: 2rem 3.5rem;
    }
		
		.postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      
      top: -20%;
      height: 130%;
      width: 55px;
    }
}

@media screen and (min-width: 769px) {
	.green::before {
		background-image: linear-gradient(
			-80deg,
			transparent 50%
		);
	}
	.green:nth-child(2n)::before {
		background-image: linear-gradient(
			80deg,
			transparent 50%
		);
	}

	.blue::before {
		background-image: linear-gradient(
			-80deg,
			transparent 50%
		);
	}
	.blue:nth-child(2n)::before {
		background-image: linear-gradient(80deg, transparent 50%);
	}
	
	.yellow::before {
		background-image: linear-gradient(
			-80deg,
			transparent 50%
		);
	}
	.yellow:nth-child(2n)::before {
		background-image: linear-gradient(
			80deg,
			transparent 50%
		);
	}
}
