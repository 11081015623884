.footer {
  display: block;
  text-align: center;
  padding-bottom: .3rem;
  background: #FFFFF0;
}

.copyright {
  color: #2F4F4F;
}
