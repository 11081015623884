::selection {
  color: #2F4F4F;
  background: #FADCD9;
}

.main-div,
.about,
.projects,
.skills,
.volunteer,
.experience,
.education,
.certificates,
.contact, 
.not-found {
  min-height: max-content;
  align-items: center;
  justify-content: center;
}

.about,
.projects,
.skills,
.volunteer,
.education,
.certificates,
.experience,
.contact, 
.not-found {
  min-height: 95vh !important;
  display: block;
  padding: 7rem 5rem 3rem 5rem;
  background: #FFFFF0;
}

.about h1,
.projects h1,
.skills h1,
.volunteer h1,
.education h1,
.certificates h1,
.experience h1,
.contact h1 {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.5rem;
  color: #fab1a9;
  margin-bottom: 5rem;
}

.timeline .experience-title,
.timeline .experience-title,
.timeline .certificates-title {
  color: #2F4F4F;
}

.timeline .experience-subtitle,
.timeline .certificates-subtitle,
.timeline .education-subtitle {
  font-size: 1rem;
}

.timeline .experience-skills,
.timeline .certificates-skills,
.timeline .education-skills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.experience-skills span,
.certificates-skills span,
.education-skills span {
  margin: 0.1rem;
  padding: 0.3rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
  background-color: #EAEAEA;
}

.vertical-timeline::before {
  background: #FADCD9 !important;
}
