/* ----------- NavBar ----------- */

.nav-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 500;
}

.scrolled {
  background: #FFFFF0;
  opacity: 0.9;
  transition: 1s ease-in;
}

.nav-contents {
  text-transform: uppercase;
  text-decoration: none;
  font-size: 0.9rem;
  color: #2F4F4F !important;
  margin: .5rem;
}

.nav-contents:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: #FAB1A9;
}

/* ----------- Main Div ----------- */

.main-div {
  min-height: 95vh !important;
  display: flex;
  background: #FFFFF0;
  justify-content: space-evenly;
}

.main-div > .header-pic {
  width: 50%;
}

.main-div > .header-name {
  font-size: 3rem;
  color: #2F4F4F;
}

.header-name h1 {
  font-family: "Indie Flower", cursive;
  font-size: larger;
}

.header-name > .titles {
  font-size: 1.4rem;
  margin-top: 1rem;
}

.titles h5 {
  padding-top: 3rem;
  color: #848383;
}

.titles small {
  font-size: medium;
  color: #BEBEBE;
}

.here-link{
  color: #FAB1A9;
  text-decoration: none;
}
