/*  Laptop Screen */
@media only screen and (max-width: 1100px) {
  /* ----------- Main Div ----------- */

  .main-div {
    padding: 1rem;
  }

  .main-div > .header-name {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .header-name h1 {
    font-size: 3rem;
  }

  .header-name > .titles {
    font-size: 1.2rem;
  }

  /* ------------- Projects ------------ */

  .projects .project-items {
    justify-content: center;
  }

  .projects .item-card {
    max-width: 70%;
  }
}

/* Tablet Screen */
@media only screen and (max-width: 770px) {
  /* ----------- Main Div ----------- */

  .main-div {
    display: block;
    text-align: center;
  }

  .main-div > .header-pic {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .main-div > .header-name {
    width: 100%;
    padding-top: 30%;
  }

  .header-name h1 {
    font-size: 3.5rem;
  }

  /* ------------- Projects ------------ */

  .projects .project-items {
    justify-content: center;
    align-content: center;
  }

  .projects .item-card {
    max-width: 80%;
  }

  /* ------------- Contact ------------ */

  .contact .main-contact {
    width: 100%;
  }

  /* ------------ Volunteer ------------- */

  .volunteer-container .volunteer-item {
    flex-wrap: wrap;
    width: 100%;
    padding: 1.5rem;
    margin: 0;
  } 

  .volunteer-item .vol-text {
    margin: .5rem;
  }

  .volunteer-item .vol-img {
    width: 100%;
    height: auto;
    margin: 0;
  }

  /* ------------ Not Found ------------- */

  .not-found .gif {
    width: 100%;
  }
}

/* Mobile Screen */
@media only screen and (max-width: 600px) {
  /* ----------- NavBar ----------- */

  .nav-bar {
    display: block;
  }

  .scrolled {
    background: none;
    opacity: 0.9;
  }

  .navbar-toggler {
    border: none !important;
    border-radius: 1rem !important;
  }

  .navbar-collapse {
    background-color: rgba(190, 190, 190, 0.9);
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
  }

  /* ----------- Main Div ----------- */

  .main-div > .header-pic {
    width: 100%;
  }

  .header-name h1 {
    font-size: 2.5rem;
  }

  .header-name > .titles {
    font-size: 1.1rem;
  }

  /* ------------- Projects ------------ */

  .projects .item-card {
    max-width: 100%;
  }

  .projects .subtitle {
    margin: 0 .5rem 1.5rem .5rem;
  }

  /* ----------- Skills -------------- */

  .skills-container .skills-item {
    margin: 0.2rem;
    min-width: 30%;
  }

  .skills-item .icon {
    font-size: 3rem;
  }

  .skills-item span {
    font-size: 1rem;
  }

  .skills .subtitle {
    margin: 0 .5rem 1.5rem .5rem;
  }

  /* ------------ Volunteer ------------- */
  .volunteer .subtitle {
    margin: 0 .5rem 1.5rem .5rem;
  }

  /* ------------ Footer ------------- */

  .footer {
    padding: 1rem .5rem;
  }

  /* ------------ Contact ------------- */

  .contact-form .name-email {
    display: block;
  }

  /* ------------ Not Found ------------- */

  .not-found .gif {
    margin-top: 5rem;
  }

  /* ------------ Common Styles ------------- */
  .about, .projects, .skills, .volunteer, .experience, .education, .certificates, .contact, .not-found {
    padding: 5rem 2.5rem 1.5rem 2.5rem;
  }

  .about h1,
  .projects h1,
  .skills h1,
  .volunteer h1,
  .experience h1,
  .education h1,
  .certificates h1,
  .contact h1 {
    margin-bottom: 3rem;
  }

  .timeline .experience-title,
  .timeline .education-title,
  .timeline .certificates-title {
    font-size: 1.3rem;
  }

  .timeline .experience-subtitle,
  .timeline .education-subtitle,
  .timeline .certificates-subtitle {
    font-size: 0.8rem;
  }

  .experience-skills span,
  .certificates-skills span,
  .education-skills span {
    font-size: 0.6rem;
  }
}
