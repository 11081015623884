.skills .subtitle {
  color: #2F4F4F;
  margin: 0 4rem 2rem 4rem;
}

.skills .skills-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.skills-container .skills-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem;
  box-shadow: 2px 2px 8px 2px #BEBEBE;
  border-radius: 1rem;
  margin: 0.8rem;
}

.skills-item .icon {
  font-size: 5rem;
}

.skills-item span {
  color: #2F4F4F;
  margin-top: 0.5rem;
}
