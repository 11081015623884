.about p {
  text-align: justify;
  hyphens: auto;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  color: #2F4F4F;
}

.about .quote {
  background-color: #eeeee4;
  border-radius: 1rem;
  padding: 1.5rem;
}

.quote svg {
  fill: #fab1a9;
  opacity: 0.7;
  margin: 0 .3rem;
}

.quote span {
  font-style: italic;
}
