.not-found {
    text-align: center;
}

.not-found h1 {
    font-family: "Indie Flower", cursive;
    color: #2F4F4F;
}

.not-found .gif {
    width: 45%;
    margin: auto;
}